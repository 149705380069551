.footer {
  height: auto;
  display: flex;
  background-color: #fff;
  border-top: 1px solid #c8c8c8;
  color: #333333;
  overflow: hidden;
  padding: 0 15px;
}
.footer-dark {
  height: auto;
  display: flex;
  background-color: #061c38;
  border-top: 1px solid #c8c8c8;
  color: #fff;
  overflow: hidden;
  width: 100%;
  padding: 0 15px;
}
.float-right {
  width: 46% !important;
  display: flex;
  /* margin-bottom: 3px; */
  /*align-items: center; */
  justify-content: flex-end;
}

.footer .nav-item.text-color,
.footer-dark .nav-item.text-color {
  color: #333;
  font-size: 1em;
  /* padding: 8px; */
  font-family: Arial, sans-serif !important;
}
.footer-dark .nav-item.tab.text-color,
.footer-dark .nav-item.tab.text-color-dark {
  font-family: Arial, sans-serif !important;
  padding: 8px;
}
.footer .nav-item.tab.text-color,
.footer-dark .nav-item.tab.text-color-dark {
  color: #111d42;
  font-family: "Broader View", "DNV Display";
  font-size: 1em;
}
.text-color-dark,
.footer-dark .nav-item.tab.text-color-dark {
  color: #ffffff;
}
.nav-item {
  display: inline-block;
  font-size: 0.75em;
  padding: 3px !important;
}
.foot-icon {
  font-size: 1.2em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.425em;
}
.svg-inline--fa.fa-w-14 {
  width: 1.425em;
}
.svg-inline--fa.fa-w-16 {
  width: 1.425em;
}
.svg-inline--fa.fa-w-10 {
  width: 1.425em;
}
.navbar-nav {
  display: block !important;
  border: 0;
  margin-top: 0 !important;
}
.nav-link {
  padding: 0 8px;
  outline: 0;
  border: 0;
  padding-left: 0 !important;
}
.nav-link:hover {
  border-color: transparent !important;
}
.float-left {
  display: flex;
  width: 54% !important;
}

.abtDNV {
  margin: 0 10px 0 0 !important;
  /* width: 54%; */
  padding: 9px 0;
  line-height: 1;
}
.abtDNV li,
.contactDNV li {
  /* float: left; */
}
.abtDNV li.nav-item.tab {
  /* width: 31%; */
  padding: 0 !important;
}
.abtDNV li.nav-item.mr-auto {
  /* width: 54%; */
  font-size: 0.825em;
}
@media screen and (min-device-width: 911px) and (max-device-width: 913px) { 
  .abtDNV {
    padding: 5px 0
  }
}
.footer .abtDNV li.nav-item.mr-auto a.nav-link,
.footer .abtDNV li.nav-item.mr-auto a.nav-link:hover,
.footer .contactDNV li.nav-item.mr-auto a.nav-link,
.footer .contactDNV li.nav-item.mr-auto a.nav-link:hover {
  font-size: 1em;
  color: #003591;
  outline: 0;
  border: 0;
}
.footer-dark .abtDNV li.nav-item.mr-auto a.nav-link,
.footer-dark .abtDNV li.nav-item.mr-auto a.nav-link:hover,
.footer-dark .contactDNV li.nav-item.mr-auto a.nav-link,
.footer-dark .contactDNV li.nav-item.mr-auto a.nav-link:hover {
  font-size: 1em;
  color: #fff;
  outline: 0;
  border: 0;
}
.contactDNV {
  margin: 0;
  /* width: 46%; */
  padding: 9px 0;
  line-height: 1;
}
.contactDNV li.nav-item.tab {
  /* width: 46%; */
  padding: 0 !important;
}
.contactDNV li.nav-item.mr-auto {
  /* width: 54%; */
  font-size: 0.825em;
}

@media only screen and (max-width: 767px) {
  .footer-dark {
    position: fixed;
  }

  .footer .float-right .nav-item.tab.text-color:first-child,
  .footer-dark .float-right .nav-item.tab.text-color-dark:first-child {
    /* width: 100%; */
    margin: 0 0 10px;
    padding: 0;
  }
  .contactDNV {
    margin: 0 10px 0 0;
    width: 46%;
  }
  .abtDNV {
    margin: 0 10px 0 0 !important;
    width: 54%;
  }
  .float-left {
    width: 54% !important;
    display: flex;
    align-items: center;
  }
  .float-right {
    width: 46% !important;
  }
}
@media only screen and (max-width: 768px) {
  
  .footer{    
    align-items: center;
    justify-content: center;
    padding-top: 2em;
    border: 0 !important;
  }
  .nav-item {
    padding-right: 2px;
  }
  .mobile-footer{
    margin-bottom: 2em;
  }
  .mobile-footer h4{
    font-size: 1.25em;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: #8cd3ef;
    letter-spacing: 1.2px;
    padding-bottom: 22px;
    margin-bottom: 20px;
  }
  .mobile-footer h4::after{
      color: #33b2e1;
      right: auto;
      left: calc(50% - 25px);
      /* width: 50px;
      border-bottom-width: 2px;
      opacity: .8;
  
      content: '';
      position: absolute;
      bottom: 0;
      border-bottom: 2px solid; */

      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50px;
      border-bottom: 2px solid #33b2e1;
  }
}


.img-container-2 {
  width: 100% !important;
  height: 400px !important;
  /* min-height: 315px !important; */
  background-color: #939393;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin-top: -65px;
  justify-content: center;
  align-items: center;
  width: 100%; */
}
.img-container-2 iframe {
  width: 100% !important;
  height: 100% !important;
}
.video-player-wrapper {
  width: 180px;
  height: 180px;
  background: #dedede 0% 0% no-repeat padding-box;
  opacity: 1;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* margin-top: 13%; */
  border-radius: 50%;

  
  /* opacity: 1;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%; */
}
.video-player {
  width: 78px;
  height: 90px;
}

.video-player svg {
  color: #939393;
  font-size: 180px;
}

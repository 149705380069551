.main-container {
  /* height: 1070px; */
  background: #040C59 0% 0% no-repeat padding-box;
  /* background: #061c38 url("../../assets/images/DataViz-RisksFirst-010.png")
  100% 36% no-repeat padding-box;
  opacity: 1; */
  display: flex;
}
.main-container-2 {
  /* height: 1070px; */
  opacity: 1;
  display: flex;
  padding: 5% 7%;
}
.homeContainer{
  height: calc(100vh - 100px - 27px);
  /* height: auto; */
  overflow-x: hidden;
  overflow-y: auto;
}
.footercontainer{
  overflow: hidden;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .homeContainer{
    /* height: calc(100vh - 90px - 25px); */
    height: auto;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .homeContainer {
    /* height: calc(100vh - 90px - 25px); */
    height: auto;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .homeContainer {
    /* height: calc(100vh - 100px - 25px); */
    height: auto;
  }
}

@media only screen and (min-width: 1900px) {
  .homeContainer{
    /* height: calc(100vh - 127px - 15px); */
    height: auto;
  }
}
.legend-container {
  position: absolute;
  bottom: 5px;
  background:#061C38;
  color: #fff;
  padding: 6px 6px 6px 9px;
  border-radius: 3px;
  max-width: 190px;
  /* font-size: 18px; */
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
  border: 1px solid #6D7985;
  z-index: 1;
}
.legend-container h3{
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: 700;
}
.legend-container .legend-item {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  padding: 2px 0;
  line-height: 1;
}
.legend-container .color-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
}
.color-icon-none{
  width: 0;
  height: 0;
  border-radius: 50%;
  margin-right: 0;
  font-style: italic;
  font-size: 12px;
}


.plus-container {
  color: white;
  /* font-size: 12px; */
  width: 12px;
  height: 12px;
  border: 1px solid white;
  position:relative;
  margin: 0 0 0 7px;
  cursor: pointer;
}
.plus-container > .plus-icon::after {
  content: "+";
}
.plus-container > .minus-icon::after {
  content: " -";
  padding-left: 3px;
}
.plus-icon {
  position : absolute;
  padding-left: 1px;
}
.legend-subheader {
  font: italic normal normal 10px/12px Arial;
  letter-spacing: 0px;
  text-align: left;
  color: #FFFFFF;
  display:inline-flex;
  padding-top: 4px;
  padding-bottom:3px;
  width:92%;
}

.legend-header {
  font: normal normal bold 14px/16px Arial;
  font-family: Arial, sans-serif;
  padding-bottom: 4px;
}
.legend-rating-icon {
  width: 12px;
  height:12px;
  padding:2px;
}
.three-rating {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  border: 1px solid #CCCBC9;
  background-color: #CCCBC9;
  margin-right: 4px;
  margin-top: 1px;
}
.two-rating {
  height: 7px;
  width:7px;
  border-radius: 50%;
  border: 1px solid #CCCBC9;
  background-color: #CCCBC9;
  margin-right: 5px;
  margin-left: 1px;
  margin-top: 2px;
}
.one-rating {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  border: 1px solid #CCCBC9;
  background-color: #CCCBC9;
  margin-right: 6px;
  margin-left: 2px;
  margin-top: 3px;

}
.rating-container {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .plus-container {
    color: white;
    font-size: 11px;
    width: 13px;
    height: 13px;
    border: 1px solid white;
    position: relative;
    margin: 2px 0 0 7px;
    cursor: pointer;
  }
  .plus-icon {
    position : absolute;
    padding-left: 2px;
  }
  .legend-container {
    max-width: 138px;
  }
}


@media only screen and (max-width: 767px){
  .legend-container {
    bottom: 10%;
  }
}
/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .legend-container {
    bottom: 15% !important;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .legend-container {
    bottom: 17%;
  }
}
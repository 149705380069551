.toggle-filter-container {
  font-size: 0.875em;
  padding: 10px 15px;
  width: 23%;
  line-height: 1;
  position: absolute;
  height: 100%;
  background: #07264d 0% 0% no-repeat padding-box;
  color: white;
  left: 0;
  top: 0;
  overflow: auto;
  /* border-left: 1px solid #c8c8c8;
  border-top: 1px solid #c8c8c8; */
  transition: 0.5s;
  transform: translate3d(-35vw, 0, 0);
  z-index: 1201;
}
.toggle-filter-container.filterWrapper {
  transform: translate3d(0vw, 0, 0);
}
.filterSection {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 125px - 20px);
  padding: 2% 5%;
}
.clearAll {
  font-family: Arial, sans-serif;
  font-size: 0.875em;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  margin: 2em 0;
  cursor: pointer;
}
.applied-filter-label,
.filter-container-label {
  font-weight: 600;
  color: #fff;
  font-size: 1.125em;
  font-family: "Arial", sans-serif;
}
.filters-back-btn {
  margin: 1.5em 0 1.5em 0em;
  cursor: pointer;
}
.applied-filter-label {
  flex: 1;
}
.key-dimension > .key-dimension-options > label {
  display: flex;
  align-items: center;
  /* padding: 2px 0; */
}
.key-dimension-options {
  margin: 0 0 15px;
}
.color-box {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.filter-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c8c8c8;
  padding: 10px 0;
  margin: 0;
}
.filter_icon {
  margin-right: 10px;
}
.filter-label {
  flex: 1;
  font-size: 1.125em;
}
.color-box {
  margin-right: 20px;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.color-box.std {
  background: #003591;
  border-radius: 50%;
}
.color-box.er {
  background: #B42126;
  border-radius: 50%;
}
.color-box.mp {
  background: #009fda;
  border-radius: 50%;
}
.dimensionLabel {
  font-size: 1.125em;
  cursor: pointer;
  color: #fff;
  margin-bottom: .5rem;
}
.dimensionCheckBox {
  margin: 0 10px 0;
  width: 20px;
  height: 20px;
}
.dimensionLabel .color-box {
  margin-right: 10px;
}
.filter_dwn_chevron {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #fff;
}
.rotate90Img {
  border: 0;
  padding: 0;
  margin: -4px -2px 0px -2px;
  cursor: pointer;
  width: 4%;
  transform: rotate(90deg);
}
.closeFiltericon {
  visibility: hidden;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  top: 23px;
}
.apply-filter-button {
  margin: 20px 0 25px 0;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 29%;
  width: 45%;
  min-height: 40px;
}
.apply-filter-button .apply-button {
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 6% 10%;
  border-radius: 0px;
  cursor: pointer;
  letter-spacing: 0.96px;
  outline: none;
  background-color: #009fda;
  color: #fff;
  border-style: none;
}
.apply-filter-button .apply-button:disabled {
  background-color: #dddddd !important;
  color: #888 !important;
}
.rotateImg {
  transform: rotate(180deg);
}
.filter-option-item {
  padding: 5px 0 5px 10px;
}
.filter-option-item > label {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0;
  margin: 0;
  line-height: normal;
}

.filter-option-item > label input[type="checkbox"] {
  margin: 0 6px 0 0;
}

.star-icon {
  margin: 2px;
}
.horizontal-line {
  margin: 0;
}
.applied-filter-options-label {
  color: #fff;
  padding: 2px;
}
.applied-filter-option-item {
  display: inline-block;
  padding: 5px;
  margin: 2px 5px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.25);
  font-size: 14px;
  font-family: Arial, sans-serif !important;
  color: #969696 !important;
}
.applied-filter-option-item > .filter-close-icon {
  padding-right: 5px;
  height: 18px;
}
.filteredItems {
  margin: 0 0 2% 0;
}
.fltTle{
  margin: 0 0 0 20px;
}
@media only screen and (max-width: 767px) {
  .toggle-filter-container {
    width: 100% !important;
    transform: translate3d(-100vw, 0, 0);
    margin-top: -51px;
    /* top: -51px !important;
    height: 109% !important; */
    height: calc(100vh - 10%);
    /* height: 100%; */
  }
 .filterSection {
    height: calc(100vh - 50%);
}
  .apply-filter-button{
    bottom: 15%;
  }
  .rotate90Img {
    visibility: hidden;
  }
  .closeFiltericon {
    visibility: visible;
    background-color: #071A31;
    position: absolute;
    right: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    top: 23px;
  }
  .fltTle{
    margin: 0 0 0 0px;
  }
}

@media screen and (min-width: 1900px) {
  .toggle-filter-container {
    width: 18%;
  }
  .rotate90Img {
    width: 2.5%;
    margin: -4px 5px 0px -9px;
    visibility: visible;
  }
  .filter-item {
    padding: 15px 0;
  }
  .closeFiltericon {
    visibility: hidden;
    background-color: #071A31;
    position: absolute;
    right: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    top: 23px;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .toggle-filter-container {
    width: 37%;
  }
  .rotate90Img {
    visibility: visible;
  }
  .closeFiltericon {
    visibility: hidden;
    background-color: #071A31;
    position: absolute;
    right: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    top: 23px;
  }
  .fltTle{
    margin: 0 0 0 0px;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .toggle-filter-container {
    width: 28%;
  }
  .rotate90Img {
    visibility: visible;
  }
  .closeFiltericon {
    visibility: hidden;
    background-color: #071A31;
    position: absolute;
    right: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    top: 23px;
  }
  .fltTle{
    margin: 0 0 0 0px;
  }
}

.MuiDrawer-root .MuiDrawer-modal,
.MuiDrawer-paper {
  font-family: arial, sans-serif !important;
  font-size: 1em !important;
  font-weight: normal !important;
  line-height: normal !important;
  width: 20% !important;
  /* max-width: 375px; */
}
.title-css,
.title-b-css {
  width: 100%;
  /* max-width: 375px; */
  /* height: 57px; */
  background-color: #B42126;
  color: #ffffff;
  text-align: center;
  /* font: normal normal bold 14px/16px Arial; */
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
  padding: 15px;
  font-size: 0.825em;
  font-family: arial, sans-serif;
}
.title-b-css {
  background-color: #003591;
}
.rightInfoPanel .MuiDrawer-paper {
  top: 140px !important;
  right: 0px !important;
  left: auto;
  background: #07264d !important;
  height: calc(100vh - 160px - 17px) !important;
  display: block !important;
}
.asset-css,
.asset-b-css {
  background-color: #B42126;
  color: #ffffff;
  text-align: left;
  /* font: normal normal bold 14px/16px Arial; */
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  padding: 10px;
  margin: 10px 20px;
  font-weight: bold;
  font-size: 0.75em;
  font-family: Arial, sans-serif;
}
.asset-b-css {
  background-color: #003591;
}
.asset-desc-css {
  max-width: 312px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 0 10px;
  margin: 10px 20px;
  font-weight: normal;
  font-size: 0.75em;
  font-family: Arial, sans-serif;
}
.focus-area-title-css {
  background: #7f7f80;
  text-align: left;
  font-weight: bold;
  font-size: 0.75em;
  font-family: Arial, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  padding: 10px;
  margin: 0px 20px;
}
.focus-area-css {
  text-align: left;
  font-weight: bold;
  font-size: 0.75em;
  font-family: Arial, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /* width: 257px; */
  margin: 10px 20px;
}
.MuiPaper-root .MuiAccordion-root {
  min-height: 0 !important;
  border-bottom: 0.5px solid #a9a9a9 !important;
  padding: 3px 0 !important;
  border-radius: 0 !important;
}
.MuiPaper-root .MuiAccordion-root .Mui-expanded {
  border-bottom: 0 !important;
}
.MuiAccordion-root.Mui-expanded {
  margin: 10px 0;
}
.MuiAccordionSummary-root {
  min-height: 0 !important;
  padding: 0 10px !important;
  font-size: 1.85em;
}
.MuiAccordionDetails-root {
  display: block !important;
}
.MuiAccordion-root.Mui-expanded:last-child,
.MuiAccordion-rounded:last-child {
  margin-bottom: 0;
  border-bottom: 0 !important;
}
.makeStyles-innerRoot-9 {
  margin: 0px 10px 0 10px !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.focus-area-desc-css {
  margin: 10px 10px 10px 10px;
  color: #ffffff;
  line-height: normal !important;
  font-size: 0.75em !important;
  font-family: Arial, sans-serif !important;
  letter-spacing: 0px !important;
}
.MuiButton-containedPrimary:hover {
  background-color: #0299d5 !important;
}
.MuiSvgIcon-root {
  width: 0.9em !important;
}
.MuiAccordionSummary-content {
  margin: 0 !important;
}
.rightInfoPanel
  .content-wrapper
  .MuiAccordionSummary-expandIcon
  .MuiSvgIcon-root {
  transform: rotate(0deg) !important;
}
.rightInfoPanel
  .content-wrapper
  .MuiAccordionSummary-expandIcon.Mui-expanded
  .MuiSvgIcon-root {
  transform: rotate(0deg) !important;
}
.MuiIconButton-root {
  padding: 0 0px !important;
}
.MuiTypography-body1 {
  line-height: normal !important;
  font-size: 0.45em !important;
  font-family: Arial, sans-serif !important;
  letter-spacing: 0px !important;
}
table.focus-area-table {
  border-collapse: collapse;
  /* width: 257px; */
  margin: 16px auto;
  color: #ffffff;
  opacity: 1;
  font-size: 0.875em;
  font-family: Arial, sans-serif;
  letter-spacing: 0px;
  width: 85%;
}
.MuiFormControlLabel-root li::marker,
[class^="makeStyles-accrodianMainLabelName-"] li::marker {
  margin: 0 !important;
}
table.focus-area-table th,
td {
  text-align: left;
  padding: 8px;
  color: #ffffff;
  font-size: 0.875em;
  font-family: Arial, sans-serif;
  letter-spacing: 0px;
}

table.focus-area-table tr:nth-child(odd) {
  background-color: #0b4080;
}
table.focus-area-table tr:nth-child(even) {
  background-color: #093366;
}
.multiline-ellipsis-text {
  overflow: hidden;
  position: relative;
  word-wrap: break-word;
  text-align: left !important;
  /* margin-right: -1em; */
  /* padding-right: 1em; */
  font-size: 1em !important;
  max-height: 13px !important;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal !important;
  max-width: 85%;
}
.rightInfoPanel .focus-area-table .multiline-ellipsis-text span {
  width: 90%;
  display: inline-block;
}
/* .rightInfoPanel .focus-area-table .multiline-ellipsis-text.pseudo-ellipsis-element::before{
  content: "";
} */
.content-wrapper {
  overflow-y: auto !important;
  overflow-x: hidden;
  height: calc(100vh - 118px - 107px);
  /* height: 538px; */
}
.view-focus-area {
  /* width: 155px;
  height: 40px; */
  background: #d8d8d8;
  text-align: center;
  font-weight: 600;
  font-size: 0.8em;
  font-family: Arial, sans-serif;
  letter-spacing: 0px;
  letter-spacing: 0px;
  color: #7d7d7d;
  opacity: 1;
  margin: 0 auto 20px;
  padding: 12px;
  width: 60%;
  cursor: pointer;
}
.expand-icon {
  position: absolute;
  height: 26px;
  width: 26px;
  top: 12px;
  right: 15px;
  cursor: pointer;
}
.panel-slider {
  width: 700px;
  height: calc(100vh - 153px - 17px);
  position: absolute;
  top: 51px;
  right: 20%;
  z-index: 1201;
}
.triangle-left {
  width: 14px;
  height: 16px;
  border-top: 9px solid transparent;
  border-right: 16px solid #6d7985;
  border-bottom: 9px solid transparent;
  bottom: 45%;
  position: absolute;
  cursor: pointer;
  left: 3px;
}
.triangle-right {
  width: 14px;
  height: 16px;
  border-top: 9px solid transparent;
  border-left: 16px solid #6d7985;
  border-bottom: 9px solid transparent;
  bottom: 45%;
  position: absolute;
  cursor: pointer;
  left: 6px;
}
.infoPanelTableLeft {
  font-weight: 700;
  width: 50%;
}
.expandCollapse-close {
  width: 22%;
  height: calc(100vh - 154px - 23px);
  /* border: 1px solid #062040; */
  background-color: #062040;
  opacity: 1;
  top: 51px;
  position: absolute;
  right: 0%;
  z-index: 1200;
  cursor: pointer;
  transition: all 300ms;
}
.expandCollapseOpen {
  width: 22%;
  height: calc(100vh - 154px - 24px);
  /* border: 1px solid #062040; */
  background-color: #062040;
  opacity: 1;
  top: 51px;
  position: absolute;
  right: 0%;
  z-index: 1200;
  cursor: pointer;
  transition: all 300ms;
}
.Mui-disabled {
  background-color: #dddddd !important;
  color: #888888 !important;
}
.rightInfoPanel .expandCollapseOpen {
  /* right: 20%; */
  width: 22.5%;
  transition: all 300ms;
}

.rightInfoPanel .expandCollapseOpenNew {
  /* right: 20%; */
  width: 22.5%;
  transition: all 300ms;
}

.rightInfoPanel .expandCollapseOpen.sliderClose {
  /* right: 20%; */
  width: 22.5%;
  transition: all 300ms;
}

.rightInfoPanel .expandCollapseOpenNew.sliderCloseNew {
  /* right: 20%; */
  /* width: 22.5%; */
  width: 100%;
  transition: all 300ms;
  background-color: #062040;
  margin-top: -90%;
  /* margin-left: 40%; */
  display: none;
}
.rightInfoPanel .expandCollapseOpen.sliderOpen {
  /* right:71.3%; */
  width: 73.5%;
  transition: all 300ms;
}

.rightInfoPanel .expandCollapseOpenNew.sliderOpenNew {
  /* right:71.3%; */
  width: 73.5%;
  transition: all 300ms;
  background-color: #062040;
  margin-top: -90%;
  display: none;
  /* margin-left: 40%; */
}

/* .rightInfoPanel .expandCollapseOpen {
  width: 73.5%;
} */

.rightInfoPanel .expandCollapse-close {
  width: 3%;
  right: 0;
  transition: all 300ms;
}

.rightInfoPanel .expandCollapse-closeNew {
  width: 3%;
  right: 0;
  transition: all 300ms;
  display: none;
}

.MuiFormControlLabel-root li.fa-list-item {
  width: 20px !important;
}
/* .MuiFormControlLabel-root li.fa-list-item::marker{
  margin-right: 10px !important;
} */

.blink {
  animation: blink-animation 0.25s steps(5, start) infinite;
  -webkit-animation: blink-animation 0.25s steps(5, start) infinite;
  /* animation: blink 1s linear infinite;*/
  animation-iteration-count: 5;
  /* background-color: red !important; */
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-animation {
  to {
    visibility: hidden;
    background-color: #EB2A34;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
    background-color: #EB2A34;
  }
}
@media only screen and (width: 600px) {
  .rightInfoPanel .expandCollapse-close, .rightInfoPanel .expandCollapse-close [class^=jss]{
    width: 40px !important;
    height: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .rightInfoPanel .MuiDrawer-root .MuiDrawer-modal,
  .rightInfoPanel .MuiDrawer-paper {
    /* width: 91% !important; */
    width: 100% !important;
    height: calc(100vh - 60px - 17px) !important;
  }
  #expand_Panel .MuiDrawer-root .MuiDrawer-docked .RightSideInfoPanel, 
  .rightInfoPanel .MuiBackdrop-root, 
  .rightInfoPanel .MuiDrawer-root {
    /* position: absolute;
    top: 100%!important;
    width: 100%!important; */
  }
  /* .MuiDrawer-paper {
    overflow-y: hidden !important;
  } */
  .rightInfoPanel .MuiDrawer-paper {
    resize: vertical;
    /* top: 15% !important; */
    /* height: 100% !important; */
    height: calc(100vh - 119px);
    transition: background-position 0.5s ease-in-out, color 0.5s ease-in-out;
    /* position: static; */
    overflow: hidden;
    top: 115px !important;
    position: fixed;
  }
  /* .rightInfoPanel .MuiDrawer-paper {
    resize: vertical;
    top: 55% !important;
    transition: background-position 0.5s ease-in-out, color 0.5s ease-in-out;    
  } */
  .rightInfoPanel .expandCollapseOpenNew.sliderCloseNew {
    /* right: 20%; */
    /* width: 22.5%; */
    width: 100%;
    transition: all 300ms;
    background-color: #062040;
    margin-top: -97%;
    /* margin-left: 40%; */
    display: inline;
  }
  .sliderClose {
    right: 91% !important;
    z-index: 1 !important;
    display: none;
  }
  .sliderOpen {
    right: 91% !important;
    display: none;
  }
  .sliderCloseNew {
    right: 91% !important;
    z-index: 1 !important;
  }
  .sliderOpenNew {
    right: 91% !important;
  }
  .panel-slider {
    width: 90%;
    right: 5px;
    z-index: 1201;
    height: calc(100vh - 130px - 34px);
  }

  /* .expand_Panel-mobile {
    animation: blink 1s linear infinite;
    animation-iteration-count: 10;
  } */
  .expandCollapseOpenNew {
    /* height: calc(100vh - 174px - 23px); */
  }
  .rightInfoPanel .expandCollapse-closeNew {
    width: auto;
    right: 0;
    transition: all 300ms;
    display: block;
    /* height: calc(100vh - 153px - 67px); */
  }
  .rightInfoPanel .expandCollapse-close, .rightInfoPanel .expandCollapse-close [class^="jss"] {
    /* height: calc(100vh - 100px - 67px);
    width: 100%;
    display: flex;
    justify-content: center;     */
    /* width: 100%;
    top: 93%;
    display: flex;
    justify-content: center;
    align-items: center; */
    width: 40px !important;
    height: 40px !important;
    /* width: 10%; */
    top: 80% !important;
    display: flex;
    justify-content: center;
    align-items: center;  
    right: 46%;
    /* height: 5%; */
    background: #07264d;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 0 2px;    
  }
  .rightInfoPanel .expandCollapseOpen.sliderClose .arrowBackIcon,
  .rightInfoPanel #expand_Panel.expandCollapseOpen.sliderClose .MuiSvgIcon-root [class^="makeStyles-arrowBack-"], 
  .rightInfoPanel .expandCollapseOpen .MuiSvgIcon-root [class^="jss"],
  .rightInfoPanel .expandCollapseOpen.sliderClose .MuiSvgIcon-root [class^="makeStyles-arrowBack-"] {
    font-size: 2.5em;
    transform: rotate(90deg) !important;
  }
  .collapse-icon {
    position: absolute;
    height: 18px;
    width: 18px;
    padding-top: 8px;
    right: 50%;
    /* align-items: center; */
    cursor: pointer;
  }
  /* 
  .MuiSvgIcon-root {
    fill: currentColor !important;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    color: #ffffff !important;
    // margin-left: 45%; 
  } */

  /* .MuiSvgIcon-rootNew {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    color: #ffffff;
  }   */
  /* .rightInfoPanel [class^="makeStyles-arrowBack-"],  [class^="makeStyles-arrowBack-"]{
    
  } */
  .rightInfoPanel .expandCollapseOpen.sliderClose .makeStyles-arrowBack-19,
  .rightInfoPanel .expandCollapseOpen.sliderClose [class^="makeStyles-arrowBack-"],
  .rightInfoPanel .expandCollapseOpen.sliderClose .arrowBackIcon,
  .rightInfoPanel .expandCollapseOpen.sliderClose .MuiSvgIcon-root [class^="makeStyles-arrowBack-"] {
    top: 0 !important;
    fill: #fff !important;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    transform: rotate(90deg) !important;
  }
  /* .rightInfoPanel .expandCollapseOpen.sliderClose .arrowBackIcon,
  .rightInfoPanel .MuiSvgIcon-root [class^="makeStyles-arrowBack-"] {
    font-size: 2.5em;
    transform: rotate(90deg) !important;
  } */
  .rightInfoPanel .expandCollapseOpen.sliderClose {
    right: 0% !important;
    width: 100%;
    transition: all 300ms;
    /* border: 1px solid red; */
    position: fixed;
    top: 79px !important;
    /* height: 100%; */
    height: calc(100vh - 81px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #062040;
    overflow: hidden;
  }
  .rightInfoPanel .expandCollapse-close .makeStyles-arrowForward-18,
  .rightInfoPanel .expandCollapse-close [class^="makeStyles-arrowForward-"],
  .rightInfoPanel .expandCollapse-close .arrowForwardIcon,
  .MuiSvgIcon-root [class^="makeStyles-arrowForward-"] {
    top: 0% !important;
    fill: #fff !important;
    /* display: flex; */
    /* padding: 5px; */
    /* position: absolute; */
    /* font-size: 2.5em; */
    align-items: center;
    justify-content: center;
    color: #fff !important;
    transform: rotate(90deg) !important;
  }
  .rightInfoPanel .expandCollapse-close .arrowForwardIcon,
  .MuiSvgIcon-root [class^="makeStyles-arrowForward-"],
  .rightInfoPanel .expandCollapse-close .MuiSvgIcon-root [class^="makeStyles-arrowForward-"] {
    font-size: 2.5em;
    transform: rotate(90deg) !important;
  }
  .rightInfoPanel
    .RightSideInfoPanel.BottomSideInfoPanelFullview
    .MuiDrawer-paper {
    width: 100% !important;
    /* top: 130px !important; */
    resize: vertical;
    top: 15% !important;
    /* padding-top: 10%; */
    transition: background-position 0.5s ease-in-out, color 0.5s ease-in-out;
    /* display: none !important;*/
    transform: translateY(-0.59px);
    visibility: visible;
  }
  .rightInfoPanel .content-wrapper {
    /* height: calc(100vh - 132px - 118px); */
    height: calc(100vh - 165px - 100px);
    overflow-x: hidden;
  }
  .rightInfoPanel .MuiDrawer-paper .content-wrapper [class^="jss"] {
    width: 90% !important;
  }
  .rightInfoPanel #expand_Panel.expandCollapseOpen.sliderClose > svg.MuiSvgIcon-root [class^="jss"]
   {
    font-size: 2.5em;
    transform: rotate(90deg) !important;
  }
  .rightInfoPanel #expand_Panel.expandCollapse-close > svg.MuiSvgIcon-root [class^="jss"] {
    font-size: 2.5em;
    transform: rotate(90deg) !important;
  }
}

@media only screen and (min-width: 1900px) {
  .expandCollapseOpen {
    height: calc(100vh - 174px - 23px);
  }

  .makeStyles-arrowBack-19 {
    top: 25%;
  }

  .rightInfoPanel .expandCollapseOpenNew.sliderCloseNew {
    display: none;
  }

  .rightInfoPanel .MuiDrawer-paper {
    top: 161px !important;
    height: calc(100vh - 174px - 23px) !important;
  }
  .rightInfoPanel .content-wrapper {
    height: calc(100vh - 121px - 118px);
    overflow-x: hidden;
  }
  .title-css,
  .title-b-css,
  .asset-css,
  .asset-b-css,
  .focus-area-title-css,
  .focus-area-css,
  table.focus-area-table {
    font-size: 0.875em;
  }
  .asset-desc-css {
    font-size: 0.925em;
  }
  .MuiTypography-body1 {
    font-size: 0.525em !important;
  }
  table.focus-area-table th,
  td {
    font-size: 1em;
  }
  .multiline-ellipsis-text {
    max-width: 58%;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .MuiDrawer-root .MuiDrawer-modal,
  .MuiDrawer-paper {
    width: 33% !important;
  }
  .rightInfoPanel .expandCollapseOpen.sliderClose {
    width: 38%;
    height: calc(100vh - 168px);
  }
  .rightInfoPanel .expandCollapse-close {
    width: 5%;
    height: calc(100vh - 168px);
  }
  .rightInfoPanel .expandCollapseOpenNew.sliderCloseNew {
    display: none;
  }
  .makeStyles-arrowForward-18 .makeStyles-arrowForward-19 {
    top: 25% !important;
  }
  .rightInfoPanel .MuiDrawer-paper {
    height: calc(100vh - 179px) !important;
  }
  .MuiDrawer-paperAnchorRight,
  .MuiDrawer-paper {
    width: 33% !important;
    height: calc(100vh - 15px) !important;
  }
  .content-wrapper {
    height: calc(100vh - 221px);
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .MuiDrawer-root .MuiDrawer-modal,
  .MuiDrawer-paper {
    width: 25% !important;
  }
  .rightInfoPanel .expandCollapseOpenNew.sliderCloseNew {
    display: none;
  }
  .rightInfoPanel .expandCollapseOpen.sliderClose {
    width: 29%;
  }
  .rightInfoPanel .expandCollapse-close {
    width: 4%;
  }
  .makeStyles-arrowForward-18 .makeStyles-arrowForward-19 {
    top: 25% !important;
  }
  .rightInfoPanel .MuiDrawer-paper {
    height: calc(100vh - 180px) !important;
  }
  .content-wrapper {
    height: calc(100vh - 222px);
  }
}

@media only screen and (max-device-width: 360px) {
  .rightInfoPanel .content-wrapper {
    /* height: calc(100vh - 112px - 37px); */
    overflow-x: hidden;
  }
  .rightInfoPanel .expandCollapse-close,
  .rightInfoPanel .expandCollapse-close [class^="jss"] {
    /* transform: rotate(90deg) !important; */
    top: 78% !important;
  }
  .rightInfoPanel .expandCollapseOpen.sliderClose,
  .rightInfoPanel .expandCollapseOpen.sliderClose [class^="jss"] {
    /* transform: rotate(270deg) !important; */
    /* top: 0% !important; */
  }
  .MuiRating-root {
    color: #ffb400;
  }
  .rightInfoPanel .expandCollapse-close,
  .rightInfoPanel .expandCollapse-close [class^="jss"] {
    /* transform: rotate(90deg) !important; */
    top: 84% !important;
    background: #07264d;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 0 2px;
    right: 40%;
    /* width: 10%;
    height: 6%; */
    width: 40px !important;
    height: 40px !important;
  }
  #expand_Panel .MuiDrawer-root .MuiDrawer-docked .RightSideInfoPanel,
  .rightInfoPanel .MuiBackdrop-root,
  .rightInfoPanel .MuiDrawer-root {
    position: absolute;
    top: 100% !important;
    width: 100% !important;
  }
  .rightInfoPanel .MuiDrawer-paper {
    height: calc(100vh - 10px - 17px) !important;
  }
}
@media only screen and (device-width: 640px) {
  .rightInfoPanel .expandCollapse-close,
  .rightInfoPanel .expandCollapse-close [class^="jss"] {
    /* transform: rotate(90deg) !important; */
    top: 74% !important;
  }
  .rightInfoPanel .expandCollapseOpen.sliderClose,
  .rightInfoPanel .expandCollapseOpen.sliderClose [class^="jss"] {
    /* top: 0% !important; */
    /* transform: rotate(270deg) !important; */
  }
  /* #expand_Panel {
    animation: blink 1s infinite;
    animation-iteration-count: 10;
  } */
}

/* Portrait */
@media only screen and (device-width: 375px) {
  .rightInfoPanel .expandCollapse-close,
  .rightInfoPanel .expandCollapse-close [class^="jss"] {
    /* transform: rotate(90deg) !important; */
    /* top: 94% !important; */
    top: 78% !important;
    background: #07264d;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 0 2px;
    /* height: 6%;
    width: 9%; */
    width: 40px !important;
    height: 40px !important;
  }
  #expand_Panel .MuiDrawer-root .MuiDrawer-docked .RightSideInfoPanel,
  .rightInfoPanel .MuiBackdrop-root,
  .rightInfoPanel .MuiDrawer-root {
    /* position: absolute;
    top: 100% !important;
    width: 100% !important; */
  }
  .rightInfoPanel .expandCollapseOpen.sliderClose,
  .rightInfoPanel .expandCollapseOpen.sliderClose [class^="jss"] {
    /* top: 0% !important; */
    /* top: 0 !important; */
    /* transform: rotate(270deg) !important; */
  }
  .rightInfoPanel .content-wrapper {
    /* height: calc(100vh - 12px - 37px); */
    /* height: calc(100vh - 132px - 118px); */
    overflow-x: hidden;
  }
  /* #expand_Panel {
    animation: blink 1s linear infinite;
    animation-iteration-count: 10;
  } */
  .rightInfoPanel .MuiDrawer-paper {
    /* height: calc(100vh - 10px - 17px) !important; */
  }
}

@media only screen and (device-width: 414px) {
  .rightInfoPanel .expandCollapse-close,
  .rightInfoPanel .expandCollapse-close [class^="jss"] {
    /* transform: rotate(90deg) !important; */
    /* top: 94% !important; */
    top: 81% !important;
    background: #07264d;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 0 2px;
    width: 40px !important;
    height: 40px !important;
    /* height: 5%; */
  }
  /* #expand_Panel .MuiDrawer-root .MuiDrawer-docked .RightSideInfoPanel,
  .rightInfoPanel .MuiBackdrop-root,
  .rightInfoPanel .MuiDrawer-root {
    position: absolute;
    top: 34px !important;
    width: 100% !important;
  } */
  /* #expand_Panel {
    animation: blink 1s linear infinite;
    animation-iteration-count: 10;
  } */
  .rightInfoPanel .expandCollapseOpen.sliderClose {
    /* top: 0 !important; */
  }
  .rightInfoPanel .MuiDrawer-paper{
    /* top: 15% !important */
  }
}
@media only screen and (device-width: 390px) {
  .rightInfoPanel .expandCollapse-close {
    width: 40px !important;
    height: 40px !important;
    /* width: 10%; */
    top: 80% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: transparent; */
    right: 46%;
    /* height: 5%; */
    border-radius: 50%;
    /* position: relative; */
    border: 1px solid #fff;
  }
  .rightInfoPanel .expandCollapseOpen.sliderClose {
    /* top: 0 !important; */
  }
  #expand_Panel .MuiDrawer-root .MuiDrawer-docked .RightSideInfoPanel,
  .rightInfoPanel .MuiBackdrop-root,
  .rightInfoPanel .MuiDrawer-root {
    /* position: absolute;
    top: 100% !important;
    width: 100% !important; */
  }
  .rightInfoPanel .MuiDrawer-paper{
    /* top: 15% !important */
  }  
  /* .network-options {
    height: 86%;
  } */
}

@media only screen and (device-width: 428px) {
  .rightInfoPanel .expandCollapse-close {
    width: 40px !important;
    height: 40px !important;
    /* width: 10%; */
    top: 79% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: transparent; */
    right: 46%;
    /* height: 5%; */
    border-radius: 50%;
    /* position: relative; */
    border: 1px solid #fff;
  }
  .rightInfoPanel .expandCollapseOpen.sliderClose {
    /* top: 0 !important; */
  }
  #expand_Panel .MuiDrawer-root .MuiDrawer-docked .RightSideInfoPanel,
  .rightInfoPanel .MuiBackdrop-root,
  .rightInfoPanel .MuiDrawer-root {
    /* position: absolute;
    top: 100% !important;
    width: 100% !important; */
  }
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .rightInfoPanel .content-wrapper {
    /* height: calc(100vh - 12px - 37px); */
    /* height: calc(100vh - 132px - 118px); */
    overflow-x: hidden;
  }
  .network-wrapper {
    /* height: calc(100vh - 80px - 40px - 40px); */
    overflow: hidden;
  }
  .rightInfoPanel .expandCollapse-close {
    /* top: 81% !important; */
  }
  /* .network-options {
    height: 88%;
  }
  .legend-container {
    bottom: 17%;
  } */
}
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .rightInfoPanel .content-wrapper {
    /* height: calc(100vh - 12px - 37px); */
    /* height: calc(100vh - 132px - 118px); */
    overflow-x: hidden;
  }
  .network-wrapper {
    /* height: calc(100vh - 80px - 40px - 40px); */
    overflow: hidden;
  }
  .rightInfoPanel .expandCollapse-close {
    top: 80%;
  }
  /* .network-options {
    height: 88%;
  }
  .legend-container {
    bottom: 17%;
  } */
}

/* Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 892px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

.rightInfoPanel .expandCollapseOpen.sliderClose .arrowBackIcon,
  .rightInfoPanel .MuiSvgIcon-root [class^="makeStyles-arrowBack-"],
  .rightInfoPanel .expandCollapseOpen.sliderClose .MuiSvgIcon-root [class^="jss"]
   {
    font-size: 2.5em;
    transform: rotate(90deg) !important;
  }
  .rightInfoPanel .expandCollapse-close .arrowForwardIcon,
  .MuiSvgIcon-root [class^="makeStyles-arrowForward-"],
  .rightInfoPanel .expandCollapse-close .MuiSvgIcon-root [class^="makeStyles-arrowForward-"],
  .rightInfoPanel .expandCollapse-close .MuiSvgIcon-root [class^="jss"] {
    font-size: 2.5em;
    transform: rotate(90deg) !important;
  }
}
.pdf-body {
  height: 100%;
}
.focus-header {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #7f7f80;
  /* //height: 57px; */
  top: 74px;
  padding-top: 14px;
  padding-bottom: 14px;
  overflow: hidden;
  font-weight: bold;
  line-height: 1;
}

.focus-sub-header {
  color: #023491;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding-top: 10px;
}
.focusArea-id {
  font-size: 10px;
  color: #878786;
  text-align: center;
  padding-top: 4px;
}
table.focus-area-modal {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 42%;
  margin: 0 auto;
  font-size: 12px;
  margin-top: 18px;
}
.panel-slider table.focus-area-modal {
  width: 45%;
}
.popupStyle {
  overflow-y: scroll;
  height: 100%;
  background-color: #e9e9e9;
  margin-bottom: 10%;
}
.popupStyleBG {
  background-color: #e9e9e9;
}
.focusArea-content {
  overflow-y: auto;
}
table.focus-area-audit {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
  margin-left: 6%;
  font-size: 12px;
  margin-top: 18px;
}
table.focus-area-modal td,
th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
  color: #4b4949;
  font-size: 12px;
  font-family: Arial, sans-serif;
}

table.focus-area-audit td,
th {
  text-align: left;
  color: #4b4949;
  font-size: 12px;
  font-family: Arial, sans-serif;
  width: 50%;
}

table.focus-area-modal tr:nth-child(even) {
  background-color: #dddddd;
}
table.focus-area-modal tr:nth-child(odd) {
  background-color: #fff;
}
table.focus-area-audit tr {
  /* page-break-inside: avoid;
  page-break-before: avoid; */
}
.multiline-ellipsis-text {
  overflow: hidden;
  position: relative;
  word-wrap: break-word;
  text-align: left !important;
  /* margin-right: -1em; */
  /* padding-right: 1em; */
  font-size: 1em !important;
  max-height: 15px !important;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal !important;
}
.multiline-ellipsis-text span {
  display: inline-block;
  width: 90%;
}
.section {
  margin-inline-start: 8%;
  margin-inline-end: 8%;
}
.section-header {
  font-size: 14px;
  color: #023491;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.padding-top {
  padding-top: 20px;
  text-align: center;
}
.content {
  font-size: 13px;
  color: #5e5f5e;
  font-family: Arial, sans-serif;
  text-align: left;
}
.left-sec {
  display: inline-block;
  width: 49%;
  height: 50%;
  vertical-align: top;
}
.right-sec {
  display: inline-block;
  width: 50%;
  height: 50%;
  vertical-align: top;
  padding-left: 30px;
}
.content-header {
  text-align: left;
  font-weight: bold;
  padding-top: 12px;
}
.close-icon {
  position: absolute;
  /* margin-left: -10%;
    height: 8%; */
  top: 6px;
  left: 5px;
  cursor: pointer;
}
.close-icon-rating {
  position: absolute;
  width: 18px;
  top: 2%;
  right: 1%;
  left: auto;
  cursor: pointer;
  z-index: 3;
}

.chevron-icon {
  position: absolute;
  top: 15px;
  left: -8px;
  width: 18px;
  transform: rotate(90deg);
  cursor: pointer;
}
.download-icon {
  position: absolute;
  /* margin-top: 40%;
    margin-left: -10%;
    height: 8%; */
  top: 60%;
}
.rating-icon {
  position: absolute;
  top: 73%;
  width: 100%;
}
.textWrapper {
  padding-top: 8px;
}
.left-aligner {
  padding-left: 10px;
}
.content-padding {
  padding-top: 15px;
}
.fa-checkbox {
  padding-top: 3px;
  padding-right: 5px;
}
.filter-option-item {
  padding-left: 10px;
}
.rating-icon img {
  cursor: pointer;
}

.star-rating {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 0 1rem;
}

.star {
  cursor: pointer;
  width: 2.5em;
  height: 2.5em;
  background: url("../assets/icons/starNotSelected.png") 100% 36% no-repeat
    padding-box;
  /* -webkit-clip-path: polygon(
      50% 0%,
      63% 38%,
      100% 38%,
      69% 59%,
      82% 100%,
      50% 75%,
      18% 100%,
      31% 59%,
      0% 38%,
      37% 38%
    );
    clip-path: polygon(
      50% 0%,
      63% 38%,
      100% 38%,
      69% 59%,
      82% 100%,
      50% 75%,
      18% 100%,
      31% 59%,
      0% 38%,
      37% 38%
    ); */
}
.faPopUp {
  position: absolute;
  left: -7%;
  top: 0;
  height: 100%;
  max-height: 100%;
}

.faPopUpLeft {
  visibility: hidden;
}
.rotate90Img {
  border: 0;
  padding: 0;
  margin: -4px -2px 0px -2px;
  cursor: pointer;
  width: 4%;
  transform: rotate(90deg);
}
.faPopUp img {
  cursor: pointer;
}
.star.selected {
  background: url("../assets/icons/starSelected.png") 100% 36% no-repeat
    padding-box;
}
.ratingShow {
  display: inline-block;
  position: absolute;
  /* margin-top: 50%; */
  /* margin-left: 0%; */
  /* height: 8%; */
  /* left: -150px; */
  left: -170px;
  top: -25px;
  color: #fff;
  text-align: center;
  z-index: 1;
  border: 1px solid #808080;
  padding: 5px;
  width: 170px;
  border-radius: 5px;
}

.ratingShow p {
  margin-left: -27px;
}
.ratingHide {
  display: none;
}
.ratingShow .apply-button {
  margin: 0 auto;
}
.apply-button,
.apply-button-disabled {
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 5% 12%;
  border-radius: 0;
  cursor: pointer;
  letter-spacing: 0.96px;
  outline: none;
  background-color: #009fda;
  color: #fff;
  border-style: none;
}
.apply-button-disabled {
  background-color: #cccbc9;
  color: #5e5f5e;
}
ul {
  font: normal normal normal 13px/14px Arial;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;
}
@media screen and (min-device-width: 911px) and (max-device-width: 913px) { 
  ul {
    line-height: 1em;
  }
}
ul li {
  list-style: none;
  position: relative;
  padding: 0 0 0 20px;
}
ul.outline-square li::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 6px;
  width: 11px;
  height: 11px;
  border: 1px solid #707070;
  background-color: #fff;
  border-width: 1px;
}
.focus-content .dnv-logo img {
  width: 144px;
  /* width: 100%; */
}

@media only screen and (max-width: 767px) {
  .faPopUpLeft {
    visibility: visible;
    position: absolute;
    left: 7%;
    top: 0;
  }
  table.focus-area-audit td,
  th {
    width: 100%;
    display: inline-block;
  }
  #popupModal.makeStyles-paper-5,
  #popupModal[class^="makeStyles-paper-"],
  #popupModal[class^="jss"] {
    /* width: 85% !important; */
    width: 100% !important;
    /* top: 53% !important; */
    height: 100% !important;
    z-index: 1201;
    /* bottom: -43% !important; */
  }

  [aria-labelledby^="simple-modal-title"] {
    z-index: 0 !important;
    position: static !important;
  }

  table.focus-area-modal {
    width: 90% !important;
  }

  .popupStyle {
    overflow-y: hidden;
    height: 99%;
    background-color: #e9e9e9;
  }
  .focusArea-content {
    overflow-y: scroll;
    height: 90%;
  }
  .faPopUp {
    position: absolute;
    right: 20%;
    left: auto;
  }
  .ratingShow {
    padding: 10px 15px;
    background-color: #061c38;
    /* width: 200px; */
    width: 252px;
    left: auto;
    right: 2px;
    top: -45px;
    border-radius: 10px;
    opacity: 1;
    /* border: 2px solid #023491; */
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
  .ratingShow p {
    font-size: 16px;
    font-family: "DNV Display";
    color: #fff;
    margin-left: -20px;
  }
  .star-rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 0 1rem;
    /* background: #efefef; */
    border-radius: 10px;
    /* padding: 4px 0px;*/
  }
  .close-icon {
    visibility: hidden;
  }
  /* .close-icon-rating {
    position: absolute;
    width: 22px;
    top: 66.5%;
    left: -30px;
    cursor: pointer;
    z-index: 3;
  } */
}
.html2canvas-container {
  width: 100px !important;
  height: 800px !important;
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  #popupModal {
    width: 80% !important;
  }
  .faPopUp {
    left: auto;
    right: 10%;
  }
  .faPopUpLeft {
    position: absolute;
    left: 7%;
    top: 0;
  }
  .ratingShow {
    padding: 10px 15px;
    background-color: #061c38;
    /* width: 200px; */
    width: 252px;
    left: auto;
    right: 2px;
    top: -45px;
    border-radius: 10px;
    opacity: 1;
    /* border: 2px solid #023491; */
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
  /* .close-icon-rating {
    position: absolute;
    width: 22px;
    top: 69%;
    left: -30px;
    cursor: pointer;
    z-index: 3;
  } */
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  #popupModal {
    width: 80% !important;
  }
  .faPopUp {
    left: auto;
    right: -1%;
  }
  .faPopUpLeft {
    position: absolute;
    left: 7%;
    top: 0;
  }
  .ratingShow {
    padding: 10px 15px;
    background-color: #061c38;
    width: 200px;
    left: auto;
    right: 2px;
    top: -45px;
    border-radius: 10px;
    opacity: 1;
    /* border: 2px solid #023491; */
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
}
@media only screen and (max-device-width: 360px) {
  #popupModal.makeStyles-paper-5,
  #popupModal[class^="makeStyles-paper-"],
  #popupModal[class^="jss"] {
    /* width: 85% !important; */
    width: 100% !important;
    /* top: 50% !important; */
    height: 100% !important;
    z-index: 1201;
  }
  .faPopUp {
    left: auto;
    right: 12%;
  }
}

@media only screen and (device-width: 375px) {
  #popupModal.makeStyles-paper-5,
  #popupModal[class^="makeStyles-paper-"],
  #popupModal[class^="jss"] {
    width: 100% !important;
    /* top: 60% !important; */
    height: 100% !important;
    z-index: 1201;
  }
  .faPopUp {
    left: auto;
    right: 12%;
  }
}

@media only screen and (device-width: 414px) {
  #popupModal.makeStyles-paper-5,
  #popupModal[class^="makeStyles-paper-"],
  #popupModal[class^="jss"] {
    width: 100% !important;
    /* top: 56% !important; */
    height: 100% !important;
    z-index: 1201;
  }
  .faPopUp {
    left: auto;
    right: 12%;
  }
  .rightInfoPanel .MuiDrawer-paper{
    height: calc(100vh - 60px - 17px) !important;
  }
}


@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1){
  #popupModal.makeStyles-paper-5,
    #popupModal[class^="makeStyles-paper-"],
    #popupModal[class^="jss"] {
      width: 100% !important;
      /* top: 60% !important; */
      height: 100% !important;
      z-index: 1201;
    }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1){
  #popupModal.makeStyles-paper-5,
    #popupModal[class^="makeStyles-paper-"],
    #popupModal[class^="jss"] {
      width: 100% !important;
      /* top: 60% !important; */
      height: 100% !important;
      z-index: 1201;
    }
}
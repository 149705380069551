.SearchBar {
  /* position: absolute;
    z-index: 1;
    left: 4.4%; */
  padding: 6px 0;
  background: #0a2c5c 0% 0% no-repeat padding-box;
  width: 100%;
  /* top:110px; */
}
.SearchBar .container {
  background: #061c38 0% 0% no-repeat padding-box;
}
.SearchBar .container .row .col-9{
  padding: 5px !important;
  max-width: 0 !important;
}
.SearchBarHighlights {
  display: flex;
  justify-content: space-around;
  font-family: Arial, sans-serif;
  width: 90%;
  font-size: 0.875em;
}
.SearchBar .MuiAutocomplete-input {
  border: 1px solid #092140;
  border-radius: 3px;
  padding: 10px 5px 10px 40px;
  background: #092140 0% 0% no-repeat padding-box;
  outline: 0;
  color: #fff;
  width: 100% !important;
  font-size: 1em;
}

.data-row {
  width: 90%;
}

.closeIcon {
  position: absolute;
  margin-left: -29px;
  width: 3%;
  padding-top: 5px;
}

.SearchBar .MuiAutocomplete-input:focus {
  outline: 1px solid #c5c4c2;
}
.SearchBarWrapper {
  position: relative;
  background: #0a2c5c 0% 0% no-repeat padding-box;
  color: #fff;
  width: 72%;
}
.SearchIcon {
  position: absolute;
  top: 30%;
  left: 1%;
  width: 20px;
  height: 20px;
}
.MuiAutocomplete-listbox {
  background: #0a2c5c;
}
.MuiAutocomplete-option {
  color: #fff;
  font-family: Arial;
  font-size: 1em;
}
.fabutton {
  font-size: 1em !important;
  border-radius: 8px;
}
.MuiAutocomplete-popper{
  display: block !important;
}
.MuiAutocomplete-option[data-focus="true"] {
  background-color: rgb(187 191 195 / 25%) !important;
}
input[type="search"] {
  font-size: 1em;
}
.MuiAutocomplete-noOptions {
  padding: 10px 16px;
  font-size: 1em;
}

@media only screen and (max-width: 767px) {
  .SearchBar {
    padding: 6px 0;
    background: #0a2c5c 0% 0% no-repeat padding-box;
    width: 100%;
    position: sticky;
    height: 50px;
  }
  .SearchBarHighlights {
    width: 100%;
  }
  .SearchBarWrapper {
    width: 95%;
  }
  .fabutton {
    font-size: 1em !important;
    width: 25px !important;
    height: 25px !important;
    /* width: 54px; */
    padding: 0 !important;
    border-radius: 20px !important;
    text-align: center !important;
    display: block !important;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .fabutton {
    font-size: 1em !important;
    width: 25px !important;
    height: 25px !important;
    /* width: 54px; */
    padding: 0 !important;
    border-radius: 20px !important;
    text-align: center !important;
    display: block !important;
  }
  .SearchBarWrapper {
    width: 90%;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .fabutton {
    font-size: 1em !important;
    width: 25px !important;
    height: 25px !important;
    /* width: 54px; */
    padding: 0 !important;
    border-radius: 20px !important;
    text-align: center !important;
    display: block !important;
  }
  .SearchBarWrapper {
    width: 90%;
  }
}

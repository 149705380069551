.headerNav{
  display: flex;
  margin: 0 0 0 7%;
}
.logo{
  display: flex;
  padding: 13px 0 0;
  height: auto;
}
.logoImg{
  display: inline-block;
  max-width:144px;
  width:8%;
  max-height:79px;
  overflow:hidden;
  box-sizing:border-box;
}
.logoImg img {
  width: 95%;
  max-width: 144px;
  max-height: 79px;
}
.header, .header-dark{
    /* height: 88px; */
    border-bottom: 1px solid #C5C4C2;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
}
.header-dark{
  background-color: #061C38 !important;
}
.float-right.paddingRight {
    /* float: right; */
    margin-bottom: 0;
    padding: 5px 0;
    align-self: flex-end;
    /* align-items: flex-end; */
    justify-content: flex-end;
    /* justify-self: flex-end; */
    /* justify-items: flex-end; */
    width: 100% !important;
    display: flex;
  }
  .header-dark .float-right.paddingRight .nav-tabs, .header .float-right.paddingRight .nav-tabs{
    border-bottom: 0;
    margin: 0 10px 0 10px !important;
  }
  .padding-right {
    padding-right: 20px;
  }
  .flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .headerlogo {
   
    /* height:34px !important; */
  
  }
  .circle {
    width: 25px;
    display: flex;
    padding: 3px 6px;
    font-size: 12px !important;
    font-family: Arial, sans-serif;
    background-color: #FFF;
    border-radius: 50%;
    color: #111D42;
    height: 100%;
    text-align: center;
    bottom: 0px !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
  }
  .circle-dark {
    width: 25px;
    display: flex;
    padding: 3px 6px;
    font-size: 12px !important;
    font-family: Arial, sans-serif;
    background-color:#0F204B;
    border-radius: 50%;
    color: #fff;
    height: 100%;
    text-align: center;
    bottom: 0px !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
  }
  .text-color {
    color: #111D42;
  }
  .text-color-dark {
    color:#ffffff;
  }
  .header .nav-item, .header-dark .nav-item {
    display: inline-block;
    font-size: .75em;
    padding: 0 3px !important;
  }
  .header .navbar-nav, .header-dark .navbar-nav  {
      display: block !important;
      border: 0;
  }
  .header .nav-link, .header-dark .nav-link{
      padding: 8px;
      cursor: pointer;
  }
  .MuiTab-textColorPrimary{
    color: #111D42 !important;
    font-size: 0.875em !important;
    font-family: Arial, sans-serif !important;
  }
  .MuiTabs-root{
    min-height: 10px !important;
  }
  .MuiTabs-root.selectedTextColorDark .MuiTab-textColorPrimary{
    color: #fff !important;
    font-size: 0.875em !important;
  }
  .MuiTabs-root .MuiTab-textColorPrimary.Mui-selected, .MuiTab-textColorPrimary{
    color: #111D42;
    font-weight: 600;
  }
  .MuiTabs-root.selectedTextColorDark .MuiTab-textColorPrimary.Mui-selected{
    color: #fff !important;
  }
  .MuiTabs-root .MuiTab-root {
    text-transform: none;
    min-width: 40px;
    min-height: 22px !important;
    /* padding-top: 0 !important; */
    padding: 0px !important;
    margin: 0 10px 0;
  }
  .MuiTabs-root .PrivateTabIndicator-colorPrimary-2, .MuiTabs-root .PrivateTabIndicator-colorPrimary-3, .MuiTabs-root .PrivateTabIndicator-colorPrimary-4{
    background-color: #0F204B;
  }
  .MuiTabs-root.selectedTextColorDark .PrivateTabIndicator-colorPrimary-2, .MuiTabs-root.selectedTextColorDark .PrivateTabIndicator-colorPrimary-3, .MuiTabs-root.selectedTextColorDark .PrivateTabIndicator-colorPrimary-4, .MuiTabs-root.selectedTextColorDark .PrivateTabIndicator-colorSecondary-5{
    background-color: #fff;
  }
  /* .MuiTabs-root.selectedTextColorDark .PrivateTabIndicator-colorSecondary-5.Component-indicator-1 > span,  .MuiTabs-root.selectedTextColorDark .PrivateTabIndicator-colorSecondary-3.Component-indicator-5 > span, .MuiTabs-root.selectedTextColorDark .jss13 > span, .MuiTabs-root.selectedTextColorDark .jss21 > span, .MuiTabs-root.selectedTextColorDark .PrivateTabIndicator-colorSecondary-3.Component-indicator-6 > span{
    background-color: #fff;
  } */
  .MuiTabs-root.selectedTextColorDark .MuiTabs-fixed [class^="PrivateTabIndicator-root-"] > span{
    background-color:#fff !important;
  }
  .MuiTabs-root [class^="PrivateTabIndicator-root-"] > span{
    background-color:#0F204B !important;
  }  
  .MuiTabs-root [class^="PrivateTabIndicator-colorPrimary-"] > span{
    background-color:#0F204B !important;
  }  
  .header .nav-item.tab.text-color,  .nav-item.tab.text-color-dark{
    color: #111D42;
    font-family: "Broader View", "DNV Display";
    font-size: 1.125em;
    /* font-weight: bold; */
  }
  .nav-item.tab.text-color-dark{
    color: #fff;
  }
  .header .headerNav [class^=jss].MuiTabs-indicator, .header .headerNav .MuiTabs-indicator{
    display: block;
    background-color:#0F204B !important;
  }
  .header-dark .headerNav [class^=jss].MuiTabs-indicator, .header-dark .headerNav .MuiTabs-indicator{
    display: block;
    background-color: #fff;
  }
  .header .btn-logout, .header .btn-logout:hover{
    text-decoration: none;    
    border: 0;
    outline: 0;
    padding: 0;
    box-shadow: 0 0 0;
    color: #111D42;
    font-family: "Broader View", 'DNV Display';
    font-size: 1.35em;
    /* font-weight: bold; */
  }
  .header-dark .btn-logout, .header-dark .btn-logout:hover{
    color: #fff;
    text-decoration: none; 
    border: 0;
    outline: 0;
    padding: 0;
    box-shadow: 0 0 0;
    font-family: "Broader View", 'DNV Display';
    font-size: 1.35em;
    /* font-weight: bold; */
  }
  .HamburgerMenu{
    display: none;
  }
  .seprtr, .header-dark .seprtr{
    color: #111D42 !important;
    font-family: "Broader View", 'DNV Display';
    font-size: 1.5em !important;
    position: relative;
    top: 0px;
    display: none;
    width: 10px;
  }
  .header-dark .seprtr{
    color: #fff !important;
  }
  
@media only screen and (max-width: 768px) {
  .logoImg{
    width: 24%;
  }
  .header, .header-dark{
    height: 79px;
  }  
  .headerNav .MuiTabs-root{
    display: none;
  }
  .float-right.paddingRight {
    align-self: flex-start;
  }
  .LogoutUrl{
    display: none !important;
  }  
  .HamburgerMenu{
    display: block;
  }
}

@media only screen and (max-width: 325px) {
	.logoImg{
		width: 28%;
	}
  .header .nav-item.tab.text-color, .nav-item.tab.text-color-dark {
    font-size: 0.9em;
  }
}

.globeCountry {
  font-size: 31px;
  /* color: #0F204B; */
  /* background: #FFF; */
    /* right: 360px; */
    top: 8px;
    position: relative;
}
.dark{
  color: #111D42
}
.dark:hover{
  color: #111D42
}
.white{
  color: #fff
}
.white:hover{
  color: #fff
}

.region-select {
  cursor: pointer;
  font-family: "Broader View", "DNV Display";
  font-size: .75em
}
.textspace {
  white-space: nowrap;
}
.textwidth{
  width: 86% !important;
}

.region-selection__nav {
    height: auto;
    border: 1px solid #d8d8d8;
    position: absolute;
    width: 240px;
    max-width: calc(100vw - 20px);
    /* overflow: hidden; */
    background: #eee;
    font-size: 16px;
    line-height: 1.88;
    color: #0f204b;
    font-family: "Avenir Next W10 Regular",Georgia;
    -webkit-transition: height .3s ease-in-out;
    transition: height .3s ease-in-out;
}

.AdminProposalModal .proposol-form-wrapper textarea{
    min-height: 90px;
}
.ModalcloseIcon{
    position: relative;
    z-index: 1400;
}
.propose-closeIcon {
    position: absolute;
    /* height: 5%; */
    right: 10px;
    cursor: pointer;
    z-index: 1401;
    width: 30px;
    height: 30px;
    top: 10px;
}
.AdminProposalModal{
    background-color: #fff !important;
}
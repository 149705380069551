.proposol-title {
  text-align: left;
  font-size: 1.75em;
  line-height: 1.75em;
  font-weight: 600;
  font-family: arial, sans-serif;
  letter-spacing: 0px;
  color: #0f204b;
  opacity: 1;
  line-height: normal;
}
.proposol-title-desc {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #5e5f5e;
  opacity: 1;
}
.proposol-title-wrapper {
  margin: 0 auto;
  width: 80%;
}
.proposol-screen {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 40px 0;
  height: calc(100vh - 140px - 67px);
  /* height: auto; */
}
.modalView{
  height: unset
}
.proposol-form-wrapper {
  margin: 0 auto;
  width: 80%;
}
.footercontainer {
  overflow: hidden;
  width: 100%;
}
#register,
#login {
  width: 300px;
  border: 1px solid #d6d7da;
  padding: 0px 15px 15px 15px;
  border-radius: 5px;
  font-family: arial;
  line-height: 16px;
  color: #333333;
  font-size: 14px;
  background: #ffffff;
  margin: 100px auto;
}

.proposol-form-wrapper textarea,
.proposol-form-wrapper .title-text,
.proposol-form-wrapper select {
  display: block;
  width: 90%;
  border: 1px solid #ccc !important;
  box-shadow: 0 0 0 0 !important;
}
.title-text {
  width: 93% !important;
}
label.group-heading,
label.groupHeading,
.group1 .ProposalLeftSection label.group-heading,
.group1 .ProposalRightSection label.group-heading,
.group2 .ProposalLeftSection label.group-heading,
.group2 .ProposalRightSection label.group-heading {
  letter-spacing: 0px;
  color: #003591;
  opacity: 1;
  font-size: 14px;
  font-weight: 600;
  margin: 25px 0 5px 0;
  display: block;
  border: 0 !important;
}

input,
textarea,
select {
  padding: 10px;
  /* border: solid 1px #ccc !important; */
  font-size: 14px;
  font-family: Arial, sans-serif !important;
  color: #969696 !important;
}

.button {
  background-color: #00bfff;
  border-color: #3ac162;
  font-weight: bold;
  padding: 12px 15px;
  color: #ffffff;
}

.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}

.sucessMsg {
  color: #6b8e23;
  margin-bottom: 10px;
}

.group1 {
  display: flex;
  width: 100%;
}
.group2 {
  display: flex;
  width: 100%;
}
.ProposalLeftSection,
.ProposalRightSection {
  width: 50%;
}
.group-wrapper {
  display: block;
}
.checkbox-cls {
  display: inline-block;
  width: 20px;
}
.propose-input {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: default;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 15px;
  margin-top: 10px;
  border: 0 !important;
}

/* Hide the browser's default checkbox */
.propose-input input {
  position: absolute;
  opacity: 0;
  cursor: default;
  height: 0;
  width: 0;
}

.check-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  /* border-radius: 50%; */
  background-color: #fff;
  border: 1px solid #ccc;
}
.textarea-cls {
  display: inline-block;
  width: 90% !important;
}
.textarea-wrapper {
  display: flex;
  margin-top: 20px;
  margin-right: 5px;
}
.RationaleTextArea {
  min-height: 240px !important;
  width: 93% !important;
}
.Add-activity-wrapper {
  display: flex;
  margin-top: 16px;
  margin-left: 20px;
  text-align: left;
  text-decoration: underline;
  font: normal normal bold 13px/15px Arial;
  letter-spacing: 0px;
  color: #238ce6;
  opacity: 1;
  cursor: pointer;
}
.Add-activity-hide {
  display: none;
}
.submit-btn-css {
  width: 169px;
  display: inline-block;
  height: 60px;
  background: #dddddd 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000021;
  opacity: 1;
  color: #888888;
  border: none;
  outline: none;
  font-size: 18px;
}
.reset-btn-css {
  width: 169px;
  height: 60px;
  background: #ffffff;
  /* box-shadow: 0px 3px 6px #00000021; */
  opacity: 1;
  color: #888888;
  border: none;
  outline: none;
  display: inline-block;
  font-size: 18px;
}
.footer-wrapper {
  float: right;
  margin: 20px 3% 20px;
}
.footer-wrapper .Mui-disabled .MuiButton-endIcon .MuiSvgIcon-root {
  color: #888 !important;
}
.footer-wrapper .MuiButton-endIcon .MuiSvgIcon-root {
}
.custom-dropdown {
  /* box-shadow: 0px 3px 6px #00000021;
  border: solid 1px #bdc7d8; */
  width: 93% !important;
  min-height: 30px;
}
.css-19o9dzd {
  width: 93% !important;
}
.css-19o9dzd,
.css-1wuhijx {
  padding: 11px 15px 11px 8px !important;
  border: 1px solid #ccc !important;
  box-shadow: 0 0 0 0 !important;
  font-size: 14px;
  font-family: Arial, sans-serif !important;
  color: #969696 !important;
}
.css-1okebmr-indicatorSeparator {
  background: transparent;
}
.ag-root-wrapper.ag-layout-normal {
  height: 80% !important;
  overflow: hidden !important;
  font-size: 14px;
  font-family: Arial, sans-serif !important;
}
.required:after {
  content: " *";
  color: red;
}
.audit-container {
  display: flex;
}
.tooltip-container {
  top: 10px;
}
#audit-label {
  width: auto;
}
.audit-container .MuiIconButton-root {
  margin: 14px 0 0px 5px !important;
  height: 1em;
}
.audit-container .MuiIconButton-root:hover {
  background-color: #fff !important;
}
.audit-container .MuiSvgIcon-root {
  fill: #238ce6 !important;
}
.MuiButton-endIcon {
  margin-left: 15px !important;
}
.AdminProposalModal .audit-container .MuiIconButton-root:hover {
  background-color: transparent !important;
}
.MuiButton-textPrimary {
  color: #003591 !important;
}
/* .MuiButton-contained:hover.Mui-disabled, .MuiButton-textPrimary:hover{
  background-color: transparent !important;
} */
.applied-filter-option-item {
  display: inline-block;
  padding: 5px;
  margin: 2px 5px;
  cursor: pointer;
  background: #d4eefd;
  font-size: 14px;
  font-family: Arial, sans-serif !important;
  color: #424242 !important;
}
.applied-filter-option-item > .filter-close-icon {
  padding-right: 5px;
  height: 18px;
}

@media only screen and (max-width: 767px) {
  .group1,
  .group2 {
    display: block;
  }
  .proposol-screen {
    /* height: calc(100vh - 50px - 105px); */
    height: auto;
  }
  .ProposalLeftSection,
  .ProposalRightSection {
    width: 100%;
  }
  .proposol-form-wrapper .title-text,
  .proposol-form-wrapper select,
  .proposol-form-wrapper .RationaleTextArea {
    display: block !important;
    width: 97% !important;
  }
  .proposol-form-wrapper textarea {
    display: block !important;
    width: 90% !important;
  }
  .title-text {
    width: 95% !important;
  }
  .custom-dropdown,
  .css-1wuhijx {
    width: 97% !important;
  }
  .css-l9bfyw {
    width: 100% !important;
  }
  .css-19o9dzd {
    width: 97% !important;
  }
  .css-12f67sd-menu {
    width: 100% !important;
  }
  #spring-modal-title {
    font-size: 2.5em !important;
  }
}

@media only screen and (min-width: 1900px) {
  .proposol-screen {
    /* height: calc(100vh - 150px - 65px); */
    height: auto;
  }
}

@font-face {
  font-family: 'DNV Display';
  src: url("./assets/fonts/DNVDisplay-Regular.ttf") format("truetype"),
  url("./assets/fonts/DNVDisplay-Regular-Web.woff2") format("woff2"),
  url("./assets/fonts/DNVDisplay-Regular-Web.woff") format("woff"),

  url('./assets/fonts/DNVDisplay-Medium.otf') format('woff2'),
  url("./assets/fonts/DNVDisplay-Medium.ttf") format("truetype"),
  url("./assets/fonts/DNVDisplay-Medium-Web.woff2") format("woff2"),
  url("./assets/fonts/DNVDisplay-Medium-Web.woff") format("woff"),

  url('./assets/fonts/DNVDisplay-Light.otf') format('woff2'),
  url("./assets/fonts/DNVDisplay-Light.ttf") format("truetype"),
  url("./assets/fonts/DNVDisplay-Light-Web.woff2") format("woff2"),
  url("./assets/fonts/DNVDisplay-Light-Web.woff") format("woff");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Broader View';
  src: url('./assets/fonts/BroaderView-Medium.otf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

html{
  font-size: 1em;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  touch-action: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif !important;
  font-size: 0.825rem !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}
body::-webkit-scrollbar{
  display: none;
}
code {
  font-family:'Arial', sans-serif, monospace !important;
}

.App {
  width: 100%;
  height: 100%;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.nav-link-switch {
  /* position: absolute;
  right: 0;
  top: 45%; */

  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 20px auto;
}
.nav-link-switch a {
  padding: 10px;
  text-decoration: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#chart {
  width: 100vmin;
  height: 100vmin;
  margin: 0 auto;
}

.pool {
  fill: #fff;
}

.options-container {
  text-align: center;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

*:hover::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #bdbbb3;
  border-radius: 4px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #bdbbb3;
  border-radius: 8px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #bdbbb3;
}

@keyframes spinner-animation {
  100% {
    transform: rotate(360deg);
  }
}

.error-msg {
  font-size: 2em;
}

.error-container {
  margin-left: 20%;
  margin-top: 10%;
}

.error-code {
  font-size: 7rem;
  color: grey;
  margin-left: 25%;
}

@media only screen and (max-width: 767px) {
  .error-msg {
    font-size: 1.5em;
  }

  .error-container {
    margin-left: 20%;
    margin-top: 50%;
  }

  .error-code {
    font-size: 5rem;
    color: grey;
    margin-left: 20%;
  }
}


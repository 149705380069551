.main-container .content-container {
  padding: 5% 5% 5% 7%;
  width: 100%;
  display: inline-block;
}
.content-title {
  /*width: 495px;
  height: 151px; */
  margin: 0 0 1em;
  text-align: left;
  font-size: 2em;
  font-family: "DNV Display";
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #a2daf7;
  opacity: 1;
  line-height: normal;
}
.content-description {
  text-align: left;
  font-size: 1em;
  letter-spacing: 0.39px;
  color: #ffffff;
  opacity: 1;
  width: 100%;
}
.focus-area {
  width: 14em;
  background: #91ffb4 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000021 !important;
  opacity: 1;
  margin-top: 3em !important;
  letter-spacing: 0px !important;
  color: #003591 !important;
  opacity: 1;
  font-size: 1em !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding: 12px !important;
  display: block !important;
  text-decoration: none !important;
  border-radius: 0 !important;
}
.focus-area:hover {
  text-decoration: none;
}
.arrow {
  border: solid #003591;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.img-container {
  width: 100%;
  display: inline-block;
  opacity: 1;
}
.NetworkChart {
  height: auto;
  width: 100%;
  margin: 100px 0 40px;
}

@media only screen and (max-width: 599px) {
.content-title {
  font-size: 1.5em;
}
.NetworkChart{
  height: auto;
  width: 100%;
  margin: -20px auto auto;  
}

}
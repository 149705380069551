.network-container {
  display: flex;
  /* margin-top: 5vh; */
  position: relative;
  /*border: 1px solid #c8c8c8;
   background: #e5f5fb; */
  background: #061c38 0% 0% no-repeat padding-box;
  height: 100%;
}
.filter-container {
  padding: 20px;
  width: 200px;
}
.network-options {
  max-width: 5vw;
  display: flex;
  flex-direction: column;
  padding: 10px 17px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #c8c8c8;
  background-color: #061c38;
  position: relative;
  z-index: 1202;
}
.network-options img {
  padding: 10px 0;
  display: block;
  cursor: pointer;
  /* width: 90%; */
  width: 32px;
  height: 32px;
  /* background: #07264D; */
  background-color: #061c38;
  /* border-radius: 50%; */
}
.network-options .filterIcon {
  margin: 60px 0 0 0;
  padding: 0px 8px !important;
  border: 1px solid #c5c4c2;
  /* width: 90%; */
  width: 32px;
  height: 32px;
}
.filterIconSelected {
  margin: 60px 0 0 0;
  border: 1px solid #fff;
  background-color: #003591 !important;
  padding: 0 8px !important;
  /* width: 90%; */
  width: 32px;
  height: 32px;
}
.network-options .expandIcon {
  width: 32px;
  height: 32px;
  border: 1px solid #fff;
  padding: 5px !important;
  margin: 5px 0;
}
.network-options .zoomInIcon {
  width: 32px;
  height: 32px;
  border: 1px solid #fff;
  padding: 5px !important;
  margin: 5px 0;
}
.network-options .zoomOutIcon {
  width: 32px;
  height: 32px;
  border: 1px solid #fff;
  padding: 5px !important;
  margin: 5px 0;
}
.network-options .settingsIcon {
  width: 32px;
  height: 32px;
  padding: 5px !important;
  margin: 5px 0;
}
#network {
  height: calc(100vh - 150px - 10px - 17px);
  width: 95vw;
  margin: 0;
  padding: 0;
  position: relative;
  background: #061c38 0% 0% no-repeat padding-box;
  /* border-right: 1px solid #c8c8c8; 
  border-left: 1px solid #c8c8c8;*/
}
.display-msg {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.zoom-options {
  position: absolute;
  right: 1px;
  top: 1px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}
.node circle {
  cursor: pointer;
  /* stroke: #000; */
  stroke-width: 1px;
}
.innerCircle,
.outerCircle {
  background: #fff;
}
.node text,
.node foreignObject {
  font-size: 16px;
  pointer-events: none;
  text-anchor: middle;
  fill: #fff;
  word-break: break-word;
  word-wrap: break-word;
}
.nodeFontSize0 {
  font-size: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: normal;
  height: 100%;
  padding: 3px;
}
.nodeFontSize1 {
  font-size: 38%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: normal;
  height: 100%;
  padding: 2px;
}
.nodeFontSize2 {
  font-size: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: normal;
  height: 100%;
  padding: 2px;
}
.nodeFontSize3 {
  font-size: 21%;
  line-height: 1.25;
  color: #000;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: break-spaces;
  padding: 2px;
}
/* [class^="nodeFontSize3 rating_"], [class^="nodeFontSize3 rating_1"]{
  font-size: 1.75px;
}
[class^="nodeFontSize3 rating_1.2"]{
  font-size: 1.8px;
}
[class^="nodeFontSize3 rating_1.3"]{
  font-size: 1.85px;
}
[class^="nodeFontSize3 rating_1.4"]{
  font-size: 1.9px;
}
[class^="nodeFontSize3 rating_1.5"]{
  font-size: 1.95px;
}
[class^="nodeFontSize3 rating_1.6"]{
  font-size: 2px;
}
[class^="nodeFontSize3 rating_1.7"]{
  font-size: 2.1px;
}
[class^="nodeFontSize3 rating_1.8"]{
  font-size: 2.15px;
}
[class^="nodeFontSize3 rating_1.9"]{
  font-size: 2.2px;
}
[class^="nodeFontSize3 rating_2"]{
  font-size: 2.25px;
}
[class^="nodeFontSize3 rating_2.1"]{
  font-size: 2.3px;
}
[class^="nodeFontSize3 rating_2.2"]{
  font-size: 2.35px;
}
[class^="nodeFontSize3 rating_2.3"]{
  font-size: 2.4px;
}
[class^="nodeFontSize3 rating_2.4"]{
  font-size: 2.45px;
}
[class^="nodeFontSize3 rating_2.5"]{
  font-size: 2.5px;
}
[class^="nodeFontSize3 rating_2.6"]{
  font-size: 2.55px;
}
[class^="nodeFontSize3 rating_2.7"]{
  font-size: 2.6px;
}
[class^="nodeFontSize3 rating_2.8"], [class^="nodeFontSize3 rating_3"]{
  font-size: 2.65px;
} */
path.link,
line.link {
  fill: none;
  /*stroke: #9ecae1; */
  stroke-width: 4px;
  cursor: pointer;
}
.sourcelevel0 {
  stroke-dasharray: 1px, 3px !important;
}
.sourcelevel1 {
  stroke-width: 2px !important;
}
.sourcelevel2 {
  stroke-width: 1px !important;
}
#network > svg {
  display: block;
  padding: 0;
  margin: 0;
  background: transparent;
  width: 92vw;
  height: 100%;
  position: relative;
  z-index: 0;
}
#network > div.tooltip {
  position: absolute;
  text-align: center;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}
#network > div.tooltip > h6 {
  margin: 0;
}
.filter-container label {
  display: block;
}
.details-container {
  padding: 20px;
  border: 1px solid;
  margin: 0 5px;
  flex: 1;
}
.network-wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  /* height: calc(100% - -10px -5px);
  height: calc(100vh - 158px - 10px); */
}
.outerCircleStyle {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  background-color: transparent;
  border-radius: 100%;
  text-align: center;
  line-height: normal;
}
.outerCircle {
  stroke: #fff;
  stroke-width: 1px !important;
}
#message-display-container,
#message-display-container h5 {
  color: #fff;
  text-align: center;
}
.spinner-css {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* iPhone 5/SE */
@media only screen and (max-width: 568px) {
  #network > svg {
    display: block;
    padding: 0;
    margin: 0;
    background: transparent;
    width: 100vw;
    height: 100%;
    position: relative;
    z-index: 0;
  }
}

@media only screen and (max-width: 767px) {
  .network-wrapper {
    /* overflow-y: scroll;
    position: sticky !important; */
    /* height: calc(100vh - 80px - 40px - 40px) */
    overflow: hidden;
  }
  #network {
    width: 100vw;
    height: calc(100vh - 70px - 50px - 10px);
  }
  .network-container {
    display: block !important;
    bottom: 0 !important;
    overflow: hidden;
    /* position: sticky !important; */
    /* overflow-y: scroll;
    overflow-x: hidden; */
  }
  .network-options {
    position: absolute;
    right: 3%;
    height: 87%;
    bottom: auto;
    z-index: 1;
    border: 0;
    background: transparent;
  }
  #network > svg {
    width: 100vw;
    z-index: 0;
  }
  .network-options .filterIcon {
    border: 1px solid #c5c4c2;
    /* background: #061c38; */
  }
  .network-options img {
    border-radius: 50%;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .network-options {
    max-width: 7vw;
  }
  #network {
    height: calc(100vh - 150px - 0px - 17px);
  }
}
@media (min-device-width: 911px) and (max-device-width: 913px)  {
  #network {
    height: calc(100vh - 150px - 1px - 23px);
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .network-options {
    max-width: 7vw;
  }
}

@media screen and (min-width: 1900px) {
  #network {
    height: calc(100vh - 157px - 23px - 17px);
  }
  .nodeFontSize0 {
    font-size: 70%;
  }
  .nodeFontSize1 {
    font-size: 42%;
  }
  .nodeFontSize2 {
    font-size: 40%;
  }
  .nodeFontSize3 {
    font-size: 22.75%;
  }
  /* [class^="nodeFontSize3 rating_"], [class^="nodeFontSize3 rating_1"]{
    font-size: 20%;
  }
  [class^="nodeFontSize3 rating_1.2"]{
    font-size: 21%;
  }
  [class^="nodeFontSize3 rating_1.3"]{
    font-size: 22%;
  }
  [class^="nodeFontSize3 rating_1.4"]{
    font-size: 23%;
  }
  [class^="nodeFontSize3 rating_1.5"]{
    font-size: 24%;
  }
  [class^="nodeFontSize3 rating_1.6"]{
    font-size: 25%;
  }
  [class^="nodeFontSize3 rating_1.7"]{
    font-size: 25%;
  }
  [class^="nodeFontSize3 rating_1.8"]{
    font-size: 25%;
  }
  [class^="nodeFontSize3 rating_1.9"]{
    font-size: 25%;
  }
  [class^="nodeFontSize3 rating_2"]{
    font-size: 25.5%;
  }
  [class^="nodeFontSize3 rating_2.1"]{
    font-size: 25.5%;
  }
  [class^="nodeFontSize3 rating_2.2"]{
    font-size: 25.5%;
  }
  [class^="nodeFontSize3 rating_2.3"]{
    font-size: 25.5%;
  }
  [class^="nodeFontSize3 rating_2.4"]{
    font-size: 26%;
  }
  [class^="nodeFontSize3 rating_2.5"]{
    font-size: 26.5%;
  }
  [class^="nodeFontSize3 rating_2.6"]{
    font-size: 27.5%;
  }
  [class^="nodeFontSize3 rating_2.7"]{
    font-size: 28.5%;
  }
  [class^="nodeFontSize3 rating_2.8"], [class^="nodeFontSize3 rating_3"]{
    font-size: 30%;
  } */
}
@media only screen and (device-width: 375px) {
  .network-options {
    height: 87%;
  }
}
@media only screen and (device-width: 414px) {
  .network-options {
    height: 88%;
  }
}
@media only screen and (device-width: 390px) {
  .network-options {
    height: 86%;
  }
}
@media only screen and (device-width: 360px) {
  .network-options {
    height: 93%;
  }
}
@media only screen and (device-width: 325px) {
  .network-options {
    height: 93%;
  }
}
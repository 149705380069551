.adminView {
  height: calc(100vh - 100px - 27px);
  /* height: auto; */
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.adminView .ag-theme-alpine {
  min-height: 400px;
  /* height: 100%; */
  height: calc(100vh - 218px - 30px);
  width: 95%;
  margin: 0px auto;
}
.ag-root-wrapper-body.ag-layout-normal {
  /* height: calc(100vh - 171px - 160px) !important;
  min-height: calc(100vh - 171px - 160px) !important; */
  height: 80%;
  min-height: 80%;
}
.adminTitle {
  text-align: left;
  font-size: 2em;
  font-weight: 600;
  font-family: arial, sans-serif;
  letter-spacing: 0px;
  color: #0f204b;
  opacity: 1;
  line-height: 2em;
  padding: 0px 0 20px;
}
.MuiSvgIcon-root {
  cursor: pointer !important;
}
.admin-tab {
  width: 95%;
  margin: 3% auto 1.5%;
}
.admin-tab .MuiTab-textColorPrimary {
  color: #0f204b !important;
  font-size: 1em !important;
  font-family: Arial, sans-serif !important;
}
.MuiTabs-root .PrivateTabIndicator-colorPrimary-2,
.MuiTabs-root .PrivateTabIndicator-colorPrimary-3,
.MuiTabs-root .PrivateTabIndicator-colorPrimary-4 {
  background-color: #0f204b !important;
}
.addNewFA {
  position: absolute;
  border: 1px solid #707070;
  font-size: 1em;
  font-family: arial, sans-serif;
  width: 110px;
  right: 3%;
  top: 20px;
  z-index: 1;
}

.resetFilter {
  position: absolute;
  border: 1px solid #707070;
  font-size: 1em;
  font-family: arial, sans-serif;
  width: 110px;
  right: 15%;
  top: 20px;
  z-index: 1;
}
.footercontainer {
  overflow: hidden;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .admin-tab .MuiTab-textColorPrimary {
    font-size: 0.9em !important;
  }
  .admin-tab {
    margin: 6% auto 1.5%;
  }
  .adminView {
    height: calc(100vh - 90px - 25px);
  }
}
@media only screen and (min-width: 1900px) {
  .adminView {
    height: calc(100vh - 127px - 15px);
  }
}

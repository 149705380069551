.MuiBackdrop-root, .MuiDrawer-root{
    top: 79px !important;
}
.logo [class^="makeStyles-root-"], .logo [class^="jss"]{
    display: none;
}
div[class^="makeStyles-openDrawer-"] > .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight,
.MuiDrawer-paperAnchorRight, .MuiDrawer-paper {
    left: auto;
    right: 0;
    /* width: 50% !important; */
    /* max-width: 220px;
    min-width: 185px; */
    background-color: #07264d !important;
    height: calc(100vh - 15px) !important;
    top: 79px !important;
}
.MuiListItemIcon-root{
    color: #fff !important;
}
.header .MuiIconButton-root, .header .MuiSvgIcon-root{
    color: #0D204B !important;
    fill: #0D204B !important;
    font-size: 2.5rem !important;
}
.header-dark .MuiIconButton-root, .header-dark  .MuiSvgIcon-root{
    color: #fff !important;
    fill: #fff !important;
    font-size: 2.5rem !important;
}
.header .MuiAppBar-colorPrimary{
    background-color: transparent !important;
}
.header-dark .MuiAppBar-colorPrimary{
    background-color: #061C38 !important;
}
.MuiPaper-elevation4{
    box-shadow: 0 0 0 0 !important;
}
.MuiTypography-displayBlock{
    font-size: 1em !important;
}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(255, 255, 255, 0.25) !important;
}

@media only screen and (max-width: 768px) {
    .logo [class^="makeStyles-root-"], .logo [class^="jss"]{
        display: inline-block;
        top: 18px !important;
    }
    /* div[class^="makeStyles-openDrawer-"] > .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight, 
    div[class^="makeStyles-openDrawer-"] > .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight,
    .MuiDrawer-paperAnchorRight, .MuiDrawer-paper {
        width: 50% !important; 
        height: calc(100vh - 15px) !important;
    }     */
}
@media only screen and (max-width: 767px) {
    div[class^="makeStyles-openDrawer-"] > .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight, 
    .MuiDrawer-paperAnchorRight, .MuiDrawer-paper {
        width: 50% !important;
    }
}
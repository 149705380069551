.content-container {
  /* padding-left: 10%;
    padding-top: 10%; */
  width: 100%;
  display: inline-block;
}
.content-title-2 {
  /* width: 495px;
    height: 88px; */
  text-align: left;
  font-size: 1.625em;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707070;
  opacity: 1;
  font-weight: 600;
  margin-bottom: 1em;
  width: 100%;
}
.content-description-2 {
  text-align: left;
  font-size: 1em;
  letter-spacing: 0.39px;
  color: #000000;
  opacity: 1;
  width: 100%;
  font-weight: 500;
}

.error-container {
    background-color: #8cc7e5;
    width: 905px;
    padding: 15px;
    font-family: "Segoe UI Light";
    margin: 50px auto;
  }
  .error-body {
    background-color: #565551;
    color: #fff;
    padding-bottom: 40px;
  }
  .error {
    border-bottom: 1px solid #fff;
    margin: 0 30px 20px 30px;
    padding: 20px 0 15px 0;
    text-align: center;
  }
  .message {
    font-family: "Segoe UI Light";
    font-size: 44pt;
    text-align: left;
  }
  
  ::ng-deep .mat-drawer-container {
    background-color: unset !important;
  }
  
  
  .soonText {
    padding-top: 30px;
    text-align: center;
  }
.footer-container,
.footer-container-dark {
  overflow: hidden;
  /* border-top: 1px solid #c8c8c8; */
  border: 0 !important;
  /* display: flex; */
  width: 100%;
  align-items: center;
  background: #061c38;
  justify-content: center;
  padding: 0 2em 2em;
  position: relative;
  overflow-y: auto;
}
.footerLinksSectionTop {
  display: block;
  text-align: center;
  padding: 1em 1em;
  background: #061c38;
}
.footerLinksSectionTop ul,
.footerLinksSectionTop ul li {
  display: inline-block;
  margin: 0;
}
.twit-icon,
.ln-icon,
.ytube-icon,
.twit-icon:hover,
.ln-icon:hover,
.fb-icon,
.ytube-icon:hover {
  background-color: #009fda;
  border-radius: 50%;
  width: 35px !important;
  height: 35px !important;
  font-size: 1em !important;
  padding: 15% !important;
  fill: #fff;
  color: #fff;
}
.twit-icon:hover,
.ln-icon:hover,
.fb-icon:hover,
.ytube-icon:hover {
  background-color: #fff;
  fill: #009fda;
  color: #009fda;
}
.footer-container .footerLinksSection,
.footer-container-dark .footerLinksSection {
  display: flex !important;
  flex-direction: row;
  background: #061c38;
  padding: 5px 15px;
}
.footer-container .footerLinksSection li a,
.footer-container-dark .footerLinksSection li a {
  color: #fff;
  font-size: 0.75rem;
  outline: none;
  border: 0;
}
.socialMediaTitle {
  text-transform: uppercase;
  color: #81ceed;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1.2px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  position: relative;
}
.socialMediaTitle:after {
  color: #2caadd;
  right: auto;
  left: calc(50% - 25px);
  width: 50px;
  content: "";
  position: absolute;
  bottom: 0;
  border-bottom: 2px solid #2caadd;
}

.footer-container .footerLinksSection,
.footer-container-dark .footerLinksSection {
  display: flex !important;
  flex-direction: column;
  /* padding: 5px 15px; */
}
.footer-container .footerLinksSection li,
.footer-container-dark .footerLinksSection li {
  padding: 5px !important;
  line-height: 1;
}
.footer-container .footerLinksSection li a,
.footer-container-dark .footerLinksSection li a {
  display: inline-block;
  color: #aadff3;
  font-size: 1em;
  line-height: 1;
  font-family: Arial, Helvetica, sans-serif;
  /* padding: 5px 10px; */
}
/* .footer-container .navbar-nav .nav-item::before, .footer-container-dark .navbar-nav .nav-item::before{
        font-family: 'Font Awesome 5 Pro';
        content: "";
        color: #009fda;
        font-weight: 100;
        font-size: 16px;
        margin-right: 12px;
    } */
.fa-angle-left,
.MuiSvgIcon-root.text {
  font-size: 1em;
  color: #b3e3f4;
}

@media only screen and (max-width: 767px) {
.footer-container-dark{
  display: none;
}
}
@media screen and (min-device-width: 818px) and (max-device-width: 821px) { 
  .footerLinksSectionTop {
    padding: 0em;
  }
}
.we-icon {
  width: 1.425em;
  padding-right: 5px;
  vertical-align: middle;
  margin-left: 8px;
}